// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.datepicker td.disabled {
  opacity: .3;
}

.order-summary-panel {
  width:358px;
  opacity:0;
  transition:opacity 400ms ease;
}

.dropdown-menu-button {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;

  border: 0;
  background: transparent;

  width: 100%;
  text-align: left;

  &:hover, &:focus {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
  }
}

.group-sortable-sortable {
  min-height: 144px;
  background-color: transparentize(black, .90);
  border-radius: 4px;
}
.group-sortable-heading, .group-sortable-item-inner {
  display: flex;
  align-items: center;
}
.group-sortable-heading-left, .group-sortable-item-inner-left {
  flex: none;
}
.group-sortable-heading-right, .group-sortable-item-inner-right {
  flex: 1;
  text-align: right;
}
.group-sortable-icon {
  cursor: move;
}
.group-sortable-icon {
  margin-right: .5em;
}
.group-sortable-button {
  margin-left: 1em;
}
.group-sortable-edit-button {
  float: right;
}

.checkbox-unit {
  margin-bottom: 0;
}
